import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import Description from "../components/localinfo";
import Seo from "../components/seo";
import Faqs from "../components/home/faq";

const LocalInfo = ({ data: { sanityLocalinfoPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let guide;
  let faq;

  sanityLocalinfoPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-3xl sm:text-[50px] leading-[45px] sm:leading-[60px]"
        h2P="pb-7"
      />
      {sanitySitesettings?.logo && <Logo logo={sanitySitesettings.logo} />}
      {sanityLocalinfoPage?.localinfoList && (
        <Description data={sanityLocalinfoPage?.localinfoList} />
      )}
      {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {guide && (
        <div>
          <CompaniesGuided data={guide} />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
};

export default LocalInfo;

export const query = graphql`
  query {
    sanityLocalinfoPage {
      pageBuilder {
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      localinfoList {
        btnText
        heading
        _rawContent
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
